
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;

}
:root{
  --primary-color:#051D59;
  --dark-blue:#061938;
  --secondary-color:#FFD13C;
  --seconday-black:#061938;
  --light-color:#D9D9D9;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f6e6f4 !important ; */
  font-size: calc(1rem * 24 / 16);
  width: 100%;
  margin-left:auto ;
  margin-right:auto ;
  /* overflow-x: hidden; */
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */


.pdf-link{
  text-decoration:underline !important;
}