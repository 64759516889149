.item-card{
    width: 195px;
    border-radius: 2rem;
    border: none;
    background-color: #F5F5F5;
    position: relative;
}

.item-card .room-link{
    position: absolute;
    width: 100px;
    background-color: var(--primary-color);
    top:70%;
    right: 0;
    border-radius: 0 0 0 1rem;
    color: #fff;
    padding: .25rem 1rem;
}

@media (max-width:500px){
    
    .item-card{
        border-radius: 1rem;
        width: 155px;
        
    }
     .room-link{
        padding: 0.75rem !important;
        max-height: 30px !important;
        height: 15px !important;
        

    }
    .item-card .room-link img{
        width: 10px;
    }
    
}