.p-tabview-nav{
    gap:1rem;
    padding-left:0;
    flex-wrap: wrap;
}
.p-unselectable-text{
    padding: .5rem 1rem;
    border: 1px solid #D8D8D8;
    border-radius: .45rem;
}
.p-unselectable-text a span{
    text-align: center;
    color: #061938;
    font-weight: 700;
    font-size: 1.25rem;
    display: flex;
    align-items: center;
    gap: .5rem;
}
.p-unselectable-text a span::before{
    content: url('../../../assets/icons/tab-box.svg')


}
.p-tabview-selected.p-highlight{
    background-color: #FFD13C ;
    border: #FFD13C;
}

.p-tabview-selected.p-highlight a span::before{
    content: url('../../../assets/icons/tab-box-active.svg');
}