.router-container{
    min-height: 100vh;
    width: 100vw;
    
}
.App{
    /* position: relative; */
    /* max-width: 100px; */
    margin-left: auto;
    margin-right: auto;
    
}

.about-main{

    background-color: #fff1c4;
}

.about-main-container, .about-list-container{
    /* max-width: 1400px; */
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
/* 
.about-container .about-main-container{
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
} */

 .about-main-container img:hover{
    filter: brightness(80%);
}
footer{
    width: 100vw !important;
}
.footer{
    max-width: 1400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

/* Top button */
.top-button{
    position: fixed;
    top: 90%;
    left: 90%;
    transform: translate(-50%,-100%);

    border-radius: 50%;
    min-width: 60px;
    width: 60px;
    height: 60px;
    background-color: var(--primary-color);
    border:none;
    text-align: center;
    align-items: center;
    justify-content: center;
}
.top-button svg{
    color:#fff;
    font-size: 30px;
}
.top-button:hover svg{
    animation: btnshake 0.75s;
    animation-iteration-count:calc(1);


}
@keyframes btnshake {
    0% { transform: translateY(-5px); }
    
  }

#about{
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;

}