@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Rozha+One&display=swap');
header{
  position: absolute;
  width: 100%;
}

@media (max-width:768px)  {
  header{
   background-color:  #FFD13C !important;
  }
  
}
.container {
 
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  max-width: 1400px;
  width: 100%;
  margin-left:auto ;
  margin-right: auto;
  height: 70px;
  padding: 20px auto;
  background-color: transparent;
  position: relative;
}

.logo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: "Rozha One", serif;
}
.logo h1{
  font-family: "Rozha One", serif;

}

.menu-icon {
  display: none;
}

.nav-elements {
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li {
  display: flex;
  align-items: center;
}

.nav-elements ul li:not(:last-child)  {
  margin-right: 40px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  text-decoration: none;
}
.nav-elements ul a:hover, .share-btn{
  color:   #061938  ;

}

.nav-elements ul a.active {
  color:   #061938  ;
  font-weight: 500;
  position: relative;
}

/* .nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
} */

.search {
  border: 1px solid #fff;
  border-radius: 0.5rem;
  color: #fff;
  font-size: 1rem;
  background-color: transparent;
  transition: 0.5s all;
}

.search-bar {
  display: flex;
  align-items: center;
}

.search-left {
  border-right: none;
}
.search-right {
  border-left: none;
}

.search::placeholder {
  color: #fff;
  opacity: 1;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 768px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #FFD13C;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }

  .nav-elements.active {
    width: 270px;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}


.logo h1{
  text-decoration: none !important;

  color: #061938;
  font-weight: bold;

}
.logo a{
  text-decoration: none !important;

}
.share-popup{
  width: 600px !important;
  overflow-x: hidden;
}

.share-popup .share-popup-container{
  text-align: center;
  gap:15px
}
.share-popup .share-popup-container .react-share__ShareButton{
  min-width: 10px;
  overflow: hidden;
}

.share-popup .share-popup-container .react-share__ShareButton svg{
  /* width:  50px !important;
  height: 50px !important; */
}
.share-popup .share-popup-container .icon-name{
  font-size: .85rem;
  font-weight: 500px;
  display: block;
  margin: .5rem;
  font-weight: 500;
}
.share-popup .share-popup-container .embed-container{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.share-popup .share-popup-container .embed-icon{
  font-size: 20px;
  color:#fff;
  background-color: #252525;
  padding: .65rem;
  border-radius: .5rem;
  

}

.share-popup .copy-container{
  overflow: hidden;
}

.p-dialog-content{
  overflow: hidden;
}
.nav-elements ul{
  overflow-x: hidden;
}
.nav-elements li a{
  cursor: pointer;
}
.share-btn{
  color:#fff !important;
}
.nav-elements ul a:hover, .share-btn:hover{
  color:   #061938 !important ;

}