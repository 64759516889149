.pdf-page {
    height: 90%;
    min-width: 1100px;
    position: relative;
}
.pdf-page .waterMark{
    position: absolute;
    top:10%;
    left: 10%;
    width: 100%;
    transform: rotate(45deg);
    color: #e8e4e450;
    font-size: 12rem;
    text-wrap: nowrap;
    
}
.pdf-page .waterMark2{
    top:35%;
}
.pdf-page .waterMark3{
    top:65%;
}
.pdf-page .headContainer{
    border-bottom: 1px solid var(--secondary-color);
    background-color: var(--dark-blue);
    padding: .5rem 1rem;
}

.headContainer .name, .headContainer .layoutName{
    font-size: 1.5rem;
    font-weight: 700;
}
.headContainer .name{
    color: #fff;
}
.pdf-page .headContainer .right{
    display: flex;
    flex-direction: column;
    gap: .5rem;
    align-items: center;
}
.pdf-page .headContainer .right h3{
    color: #fff;
    font-family: "Rozha One", serif;
}
.pdf-page .headContainer .logo-block{
    padding: 1rem;
    /* background-color: #fff; */
    /* border-radius: 50%; */
    text-align: center;
    display: inline-block;


}
.pdf-page .headContainer .logo-block .logo{
    width: 150px;  
    margin-right: 40px;  
}

.pdf-page .body-container{
    background: linear-gradient(to top,var(--light-color) 70%, var(--primary-color) 30%);
}
.pdf-page .body-container .label,.pdf-page .body-container .content{
    margin-bottom: .5rem;
}
.pdf-page .body-container .label{
    font-weight: 500;
}
.pdf-page .body-container .count span{
    background-color: #fff;
    color:var(--primary-color);
    width: 30px;
    display: inline-block;
    padding: 3px;
    text-align: center;
    margin-left: 5px;
    border-radius: 5px;
    box-shadow:
    2px 2px 0px 3px var(--primary-color),
    4px 4px 0px 3px var(--secondary-color);
}

.pdf-page .body-container .roomContainer{
    border: 5px solid #fff;
    background-color: #fff;
}

.pdf-page .body-container .roomContainer .room-head{
    color: #fff;
    text-transform: uppercase;
    background-color: var(--seconday-black);
    text-align: center;
    padding: .75rem;
    font-size: 1.25rem;
    font-weight: 500;
}


.pdf-page .body-container .room-table thead th{
    background-color: var(--secondary-color);
    padding: .75rem auto;
}
.pdf-page .body-container .room-table .image-container{
    width: 100px; /* Set the width of the container */
    height: 100px; /* Set the height of the container */
    overflow: hidden; 
}
.pdf-page .body-container .room-table td,.pdf-page .body-container .room-table th{
    text-align: center;
    vertical-align: middle;
    border: 4px solid var(--secondary-color);
}

.pdf-page .body-container .room-table .image-container img {
    transform: scale(2.5);
    width: 100%; /* Make the image fill the container horizontally */
    height: 100px; /* Make the image fill the container vertically */
    object-position: center; /* Center the image */
  }

  

/* Style the first row */
.pdf-page .body-container .room-table tr:first-child td {
    border-top: none; /* Remove top border for the first row */
  }
  
  /* Style the last row */
  .pdf-page .body-container .room-table tr:last-child td {
    border-bottom: none; /* Remove bottom border for the last row */
  }
  
  /* Style the first column */
  .pdf-page .body-container .room-table td:first-child {
    border-left: none; /* Remove left border for the first column */
  }
  
  /* Style the last column */
  .pdf-page .body-container .room-table td:last-child {
    border-right: none; /* Remove right border for the last column */
  }

  .pdf-page .pdf-footer{
    background-color:var(--light-color) ;
  }

  .pdf-page .section-with-page-break {
    page-break-before: always; /* or avoid, left, right */
    page-break-after: always;
    page-break-inside: auto;
    }

    .pdf-page .whole-img{
        /* margin-top: 200px; */
        width: 100%;
        /* transform: rotate(90deg); */
        height:auto;
        /* transform: rotate(90deg); */
    }