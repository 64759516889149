.top-cont{
    width: 100%;
    background: url('../../assets/yellow.svg'), #FFD13C;
}
.upload-section{
    max-width: 400px;
    margin:2rem auto ;
}
.uploadContainer{
    border:1px dashed #051D59;
    padding: .5rem 1rem;
    border-radius: .5rem;
    margin:1rem auto  ;
}

.upload-section .uploadContainer .uploadIcon, .fileIcon{
    width: 4.5rem;
}
.upload-section .uploadContainer .fileIcon{
    width: 2.5rem;

}
button:disabled{
  /* border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666; */
  cursor: not-allowed;
}

/* Antd Drag and Drop */
.ant-upload-wrapper .ant-upload-drag{
    background-color: transparent;
    border: none;
}

.ant-upload-list-text{
    display: none;
}
.ant-upload-wrapper .ant-upload-drag .ant-upload{
    padding: 0;
}
.loader-container{
    position: relative;
    height: 200px;
    width: 500px;
    margin: auto;

}
.loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

#aknowledge,#aknowledge::after{
    width: 2rem;
    accent-color: #051D59;
}
#aknowledge:checked{
    width: 2rem;

}

#aknowledge::before{
    width: 2rem;

}
.aknowledge-content a{
    color: var(--primary-color);
    font-weight: 500 ;
}

.ant-modal-footer button{
    display: none;
}

/* .ant-modal-content{
    background-color: #fff1f0 !important;
} */