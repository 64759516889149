.primaryBtn {
  border-radius: 0.5rem;
  background-color: #051d59;
  color: #fff;
  font-size: 1rem;
  border: none;
}
.primaryBtn:hover {
  background-color: #04143e;
}

.secondaryBtn {
  border-radius: 0.5rem;
  background-color: #ffd13c;
  color: #252525;
  font-size: 1rem;
  border: none;
}
.secondaryBtn:hover {
  background-color: #ffda63;
}
.secondaryBtn-border {
  border: 1px solid #ffd13c;
  border-radius: 0.5rem;
  color: #252525;
  font-size: 1rem;
  background-color: transparent;
  transition: 2s all;
}
.secondaryBtn-border:hover {
  background-color: #ffd13c;
  transition: 2s all;
}

.loginBtn {
  border: 1px solid #fff;
  border-radius: 0.5rem;
  color: #fff;
  font-size: 1rem;
  background-color: transparent;
  transition: 0.5s all;
}
.loginBtn:hover {
  background-color: #fff;
  color: #061938;
  transition: 0.5s all;
}
