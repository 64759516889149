.subscription{
    max-width: 1400px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
.subscription form{
    margin: 1rem auto;

}
.subscription .input-containers{
    display: flex;
    gap:16px;
    justify-content: center;
}
.subscription .form-container{
    border: 2px dotted var(--primary-color);
    border-radius: 2rem;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
    margin: 1rem auto .5rem auto;
} 
.subscription .form-container.input-1{
    width:25vw;
}
.subscription .form-container.input-2{
    width:55vw;
}
.subscription .form-container input{
    width: 100%;
    border:  none;
    padding: 5px 1rem;
}

.subscription .form-container input:focus{
    outline: none;
}

.subscription .form-container .subscribeBtn{
    border-radius: 2rem;
    font-size: .95rem;

}

@media (max-width:700px){
    .subscription .input-containers{
        flex-direction: column;
    }
    .subscription .form-container.input-1,.subscription .form-container.input-2{
        width: 90vw;
    }
    .subscription form{
        width: 90%;
        margin: 1rem auto;
    
    }
    .subscription .form-container{
        width: 100%;
    }

    .subscription .form-container .subscribeBtn{
        
        padding: .5rem 1.5rem;
        text-wrap: nowrap;
    }
}

@media (max-width:440px){
    .subscription .form-container .subscribeBtn{
        min-width: 100px;
        margin-left: -70px;
    }
}
@media (max-width:350px){
    .subscription .form-container .subscribeBtn{
        min-width: 120px;
        margin-left: -100px;
    }
}