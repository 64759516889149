.progress-container {
    width: 100%;
    height: 4px;
    background-color: #f0f0f0;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .progress-bar {
    width: 100%;
    height:100%;
    background-color: #051D59;
    animation: progressAnimation 2s linear infinite; /* Animation properties */
  }
  
  @keyframes progressAnimation {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
  