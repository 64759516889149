.layoutTopContainer{
    background: linear-gradient(to left,#fff 85%, #FFD13C 15%);
    border-bottom: 1px solid #D9D9D9;
    position: relative;
}

.layoutTopContainer .box-img{
    position: absolute;
    z-index: 1;

}

.layoutTopContainer .box-img.img-1{
    right:5%;
    top:90%;
    transform: translate(0,0);
}
.layoutTopContainer .box-img.img-2{
    right:50%;
    top:4%;
    transform: translate(0,-10%);
}


@media (max-width:1000px){
    .layoutTopContainer{
        background:#fff;
    }
    .layoutTopContainer .box-img{
        display: none;
    }
}