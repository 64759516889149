.about-card{
    transition: 0.6s ease-in;
    border: none;
    position: relative;
    margin-top: 60px;
    width: 48%;
}

@media (max-width:720px){
    .about-card{
        width: 99%;
    }
}

.about-card:hover{
    transition-delay:0.2s ;


}

.about-card:hover{
    /* animation: cardAnim .5s all;
    background-color: #051D59; */
    background-position: left;


}

.about-card:hover span,.about-card:hover p{
    
}

.about-card .img-container img{
    font-size: 40px;
    padding: 10px;
    
    display: inline-block;
   
}
.about-card:hover .img-container img{
    animation: shake 0.5s;

    /* When the animation is finished, start again */
    animation-iteration-count:calc(2);

}
.about-card .img-container{
   
    border-radius: 50%;
    top:0%;
    left: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
    margin-bottom: 60px;
    padding: 10px;



}
@keyframes shake {
    0% { transform: translate(1px, 1px) rotate(0deg); }
    
    100% { transform: translate(-2px, 1px) rotate(0deg); }
  }