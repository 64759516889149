
.roomCard .left .room-icon{
    width: 3rem;
}

.roomCard .left .head{
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: .5rem;
    
}
.roomCard .left .label{
    font-weight: 500;
    
}
.roomCard .left .content{
    padding: 0;
}
.room-view {
    width:12rem;
    margin: auto;
    overflow: hidden;

}
.room-view img{
    /* width:12rem; */
    transform: scale(2.75);
    width: 100%; /* Make the image fill the container horizontally */
    height: 100%; /* Make the image fill the container vertically */
    object-position: center; /* Center the image */
}