.p-dialog-mask{
    background-color: #0000006f;

}
.dialog-container{
    background-color: #fff;
    padding: 1rem 1.5rem;
    border-radius: 1rem;
    width: 50vw;

}

.error-dialog{
    background:linear-gradient(to bottom,#FEDDDD,#FFFFFF);

}
.error-dialog .p-dialog-header-close svg{
    color:#FF3838;
}

.success-dialog{
    background:linear-gradient(to bottom,#C1FFE4,#FFFFFF);

}

.success-dialog .p-dialog-header-close svg{
    color:#2CC485;
}

.info-dialog{
    background:linear-gradient(to bottom,#D8EDFF,#FFFFFF);

}
.info-dialog .p-dialog-header-close svg{
    color:#6AB9FF;
}

.warning-dialog{
    background:linear-gradient(to bottom,#FFE9D5,#FFFFFF);
    
}
.warning-dialog .p-dialog-header-close svg{
    color:#FFB545;
}

.dialog-container .p-dialog-title{
    font-weight: bold;
    font-size: 1.45rem;
}

.dialog-container.share-popup .p-dialog-header{
    justify-content: center;
    position: relative;
}

.dialog-container.share-popup .p-dialog-title{
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
}
.dialog-container.share-popup .p-dialog-header-icons{
    position: absolute;
    right: 10px;
}

@media (max-width:675px) {
    .dialog-container{
        width: 500px;
    }
    
}
@media (max-width:500px) {
    .dialog-container{
        width: 100%;
    }
    
}