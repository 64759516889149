/* Background COLOR */
.bgPrimary{    
    background-color: #051D59;
}
.bgSecondary{
    background-color: #FFD13C;
}


/* TEXT SIZE */
.primary-text{
    color: #051D59;
}
.secondary-text{
    color: #FFD13C;
}
.text-justify{
    text-align: justify;
}
/* MARGIN */
.mt-7{
    margin-top: 4rem;
}
.pt-7{
    padding-top: 4rem;
}
.pt-8{
    padding-top: 5rem;
}

/* COMMON */
.d-hide{
    visibility: hidden;
}
.text-h1{
    font-size: 3.5rem;
}

.cursor-pointer{
    cursor: pointer;
}


/* Form container */
h5{
    color:#3b3a3a
}
.icon{
    padding: 8px;
    background-color: #f6e6f4;
    border-radius: 5px;
}
.icon svg{
    font-size: 20px;
    color: #a0008d;
}
.icon-label-container{
    display: flex;
    flex-direction: column;
    margin-left: 5px;
}

.head-label{
    color: #3b3a3a;
}

.count-label{
    font-weight: bold;
}

/* Flex */
.flex-gap-half{
    gap: .5rem;

}
.flex-gap-1{
    gap: 1rem;
}