.dimension-container .dimension-head:first-child{
    display: none !important;
}
.dimension-container .dimension-head1{
    display: block !important;
}
.dimension-container .layoutImg{
    width: 600px !important;
    z-index: 3;
}
.dimension-content .block{
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
}
.dimension-content .title{
    color:var(--primary-color);
    font-weight: 600;
    width: 30%;

}

.dimension-content .result{
    justify-self: start;
}
.notes-content{
    width: 75%;
    margin-bottom: 20px;
    margin-top: 20px;
}

@media (max-width:1050px){
    .dimension-content .title{
        width: 30%;
    }
}

@media (max-width:1000px){    
    .dimension-container{
        flex-direction: column;
    }
    .dimension-container .dimension-head:first-child{
        display: block !important;
    }
    .dimension-container .dimension-head1{
       text-align: center;
       padding-bottom: 10px;
    }
    .layoutImg{
        width:100% ;
        z-index: 3;
    }
    .dimension-content{
        /* padding: 1rem 10rem !important; */
        position: relative;
       top: 10px;
       z-index: 0;
       align-items: center;
       margin: auto;
    }
    .dimension-content .block{
        max-width: 300px;
        margin: auto;
    }
    .notes-content{
        width: 100%;
    }
}

@media (max-width:1000px){
    .dimension-content{
        
    }
}


@media (max-width:345px){  
    .dimension-content .title{
        width: 30%;
    }
}